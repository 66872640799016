import "./App.css";
import NavBar from "./Components/NavBar";
import PageFunctionIntro from "./Pages/PageFunctionIntro";
import PageServiceIntro from "./Pages/PageServiceIntro";
import PagePrice from "./Pages/PagePrice";
import Page404 from "./Pages/Page404";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Footer from "./Components/Footer";

function App() {
  return (
    <div>
      <div className=" flex flex-col overflow-hidden">
        {/* <div className="w-screen mx-auto pb-20">
          <NavBar />
        </div> */}
        <div className="flex-grow">
          <Routes>
            <Route index path="/" element={<PageFunctionIntro />} />
            <Route path="serviceIntro" element={<PageServiceIntro />} />
            <Route path="price" element={<PagePrice />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </div>
        {/* <div className="w-screen mx-auto py-4 flex justify-center bg-gray-200">
          <Footer />
        </div> */}
      </div>
    </div>
  );
}

export default App;
