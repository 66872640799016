import { useState } from "react";
import Footer from "../Components/Footer";
import NavBar from "../Components/NavBar";
import logologo from "../img/logologo 복사 복사.png";

import imgLine from "../img/PageFunction/라인.png";
import imgCustomer from "../img/PageFunction/ic_add_customer.png";
import imgCS from "../img/PageFunction/ic_cs.png";
import imgMKT from "../img/PageFunction/ic_mkt.png";

import imgMac from "../img/PageFunction/mac.png";
import imgPhone from "../img/PageFunction/Megaphone 1.png";

import imgCoinUp from "../img/PageFunction/ic_coin_up.png";
import imgGroup from "../img/PageFunction/ic_group.png";
import imgUserThumb from "../img/PageFunction/ic_user_thumb.png";
import imgCartUp from "../img/PageFunction/ic_cart_up.png";

import imgCRM from "../img/PageFunction/crm.png";
import imgLaptop from "../img/PageFunction/img.png";
import gurib2581 from "../img/PageFunction/gurib2581.png";
import ictime3x from "../img/PageFunction/ic_time@3x.png";
import icre3x from "../img/PageFunction/ic_re@3x.png";
import icm3x from "../img/PageFunction/ic_m@3x.png";
import icuserplus3x from "../img/PageFunction/ic_userplus@3x.png";
import Pdf from "../img/PageFunction/Sales_Material.pdf";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Mousewheel, EffectFade, Pagination } from "swiper";

export default function PageFunctionIntro() {
  const [navbarColour, setnavbarColour] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 500) {
      setnavbarColour(true);
    } else {
      setnavbarColour(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div className="w-screen mx-auto  ">
        <NavBar
          navbarColour={navbarColour}
          navbarbgColour="bg-transparent"
          blackText="text-[#30313c]"
          whiteText="text-white"
        />
      </div>
      <div className="w-full mt-10 md:mt-0 ">
        <Swiper
          effect={"fade"}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          slidesPerView={1}
          spaceBetween={30}
          modules={[Autoplay, Mousewheel, Pagination, EffectFade]}
          className="mySwiper2 "
        >
          <SwiperSlide>
            <div className="relative flex justify-center items-center ">
              <div className="absolute  pb-16 md:pb-0 text-white text-xs md:text-2xl font-bold flex flex-col tracking-wider gap-y-2">
                <div className="h-44 flex-col flex justify-end items-center gap-y-5 ">
                  <div className="flex-col justify-center items-center ">
                    <div>이제 내 브랜드를 키우세요</div>
                  </div>
                  <div className="text-[8px]  md:text-lg  md:pt-6 space-x-3">
                    <a
                      href="https://www.incento.kr/register"
                      className="px-4 py-2 text-[#30313c] bg-white rounded-md shadow hover:bg-[#30313c] hover:text-white"
                    >
                      무료로 시작하기
                    </a>
                    <a
                      href={Pdf}
                      target="_blank"
                      className="px-4 py-2 text-white bg-[#30313c] rounded-md shadow hover:text-[#30313c] hover:bg-gray-100"
                    >
                      서비스 소개서 다운
                    </a>
                  </div>
                </div>
              </div>

              <img src={logologo} alt="" className="object-cover" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="relative flex justify-center items-center">
              <div className="absolute pb-16 md:pb-0 text-white text-xs md:text-2xl font-bold flex flex-col tracking-wider gap-y-2">
                <div className="h-44   flex-col flex justify-end items-center">
                  <div className="flex-col justify-center items-center  pb-5 md:pb-0">
                    <div>마켓플레이스의 의존도를 낮추고 </div>
                    <div>리퍼럴 마케팅 자동화부터 고객 관리까지</div>
                    <div> 인센토로 해결하세요</div>
                  </div>
                  <div className="text-[8px]  md:text-lg md:pt-6 space-x-3">
                    <a
                      href="https://www.incento.kr/register"
                      className="px-4 py-2 text-[#30313c] bg-white rounded-md shadow hover:bg-[#30313c] hover:text-white"
                    >
                      무료로 시작하기
                    </a>
                    <a
                      href={Pdf}
                      target="_blank"
                      className="px-4 py-2 text-white bg-[#30313c] rounded-md shadow hover:text-[#30313c] hover:bg-gray-100"
                    >
                      서비스 소개서 다운
                    </a>
                  </div>
                </div>
              </div>

              <img src={logologo} alt="" className="object-cover" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="component fifth-component bg-[#f3fafd]   ">
        <section id="home">
          <div className=" w-full relative pb-36 pt-10">
            <div className="flex justify-center md:justify-start  items-center md:flex-none    md:px-52 pb-20 md:pb-0 pt-20 text-xl md:text-2xl font-bold ">
              왜 <span className="text-sky-600">인센토</span>가 필요한가요?
            </div>
            <img src={imgLine} alt="" />
            <div className="absolute w-full bottom-20 md:bottom-24 text-[#30313c]">
              <div className="flex justify-between items-center px-10 md:px-72  ">
                <div className="flex flex-col justify-center items-center gap-y-7">
                  <div className="flex justify-center items-center rounded-3xl  px-4 py-4">
                    <div className="flex justify-center items-center rounded-2xl bg-white drop-shadow-md px-3.5 pt-[18px] pb-[18px] md:pt-[20px] md:pb-[20px]">
                      <div className="hidden md:block">
                        <img src={imgCustomer} alt="" width={52} />
                      </div>
                      <div className="block md:hidden">
                        <img src={imgCustomer} alt="" width={26} />
                      </div>
                    </div>
                  </div>

                  <div className="text-xs md:text-lg font-bold">고객 획득</div>
                </div>
                <div className="flex flex-col justify-center items-center gap-y-7">
                  <div className="flex justify-center items-center rounded-3xl  px-4 py-4">
                    <div className="flex justify-center items-center rounded-2xl bg-white drop-shadow-md px-5 md:px-6 pt-[18px] pb-[18px] md:pt-[19px] md:pb-[19px]">
                      <div className="hidden md:block">
                        <img src={imgCS} alt="" width={30} />
                      </div>
                      <div className="block md:hidden">
                        <img src={imgCS} alt="" width={15} />
                      </div>
                    </div>
                  </div>

                  <div className=" text-xs md:text-lg font-bold">
                    고객 세일즈
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center gap-y-7">
                  <div className="flex justify-center items-center rounded-3xl bg-blue-200/80 px-4 py-4">
                    <div className="flex justify-center items-center rounded-2xl bg-white border-2 border-sky-600 drop-shadow-md px-5 md:px-6 pt-[18px] pb-[18px] md:pt-[19px] md:pb-[19px]">
                      <div className="hidden md:block">
                        <img src={imgMKT} alt="" width={30} />
                      </div>

                      <div className="block md:hidden">
                        <img src={imgMKT} alt="" width={15} />
                      </div>
                    </div>
                  </div>

                  <div className=" text-xs md:text-lg font-bold">
                    매출 높이기
                  </div>
                </div>
              </div>
            </div>
            <div className="flex absolute w-full bottom-0 justify-center items-center text-[#30313c] font-bold text-lg md:text-xl">
              <div>고객 획득과 세일즈는 인센토에 맡기세요</div>
            </div>
          </div>

          <div className="w-full pt-20 md:px-52">
            <div className="flex flex-col justify-center items-center md:items-start gap-y-4">
              <div className="text-sm text-sky-600 font-bold">고객 사례</div>
              <div className="text-xl md:text-2xl text-[#30313c] font-bold">
                고객 획득과 세일즈는 인센토에 맡기세요
              </div>
            </div>

            <div className="flex justify-between items-center w-full  pt-9 md:pt-[3.5rem]">
              <div className="flex justify-center md:justify-start items-center w-full">
                <span className="text-xl md:text-2xl font-bold text-[#30313c]">
                  R 사
                </span>
                <span className="text-sm md:text-lg text-gray-400 flex justify-center items-center pl-2 gap-x-1">
                  <img src={ictime3x} alt="" className="w-7 md:w-9" />
                  60일 도입 후
                </span>
              </div>
              <div className="flex justify-center md:justify-start items-center w-full ">
                <span className="text-xl md:text-2xl font-bold text-[#30313c]">
                  S 사
                </span>
                <span className="text-sm md:text-lg text-gray-400 flex justify-center items-center pl-2 gap-x-1">
                  <img src={ictime3x} alt="" className="w-7 md:w-9" />
                  37일 도입 후
                </span>
              </div>
            </div>

            <div className="flex flex-col md:flex-row gap-y-2 md:gap-y-0 justify-between items-center w-full  pt-12 px-3 md:px-0">
              {/* --------ist------- */}
              <div className="flex justify-center items-center w-full">
                <div className="flex justify-start items-center w-full">
                  <div className="bg-blue-200 rounded-full p-2 flex justify-center items-center">
                    <img src={icre3x} alt="" className="w-11" />
                  </div>
                  <div className="flex-col flex justify-center items-start text-[#30313c] text-lg font-semibold gap-y-1 pl-3">
                    <span>리퍼럴</span>
                    <span>232입</span>
                  </div>
                </div>
                <div className="flex justify-end md:justify-start items-center w-full">
                  <div className="bg-blue-200 rounded-full p-2 flex justify-center items-center">
                    <img src={icuserplus3x} alt="" className="w-11" />
                  </div>
                  <div className="flex-col flex justify-center items-start text-[#30313c] text-lg font-semibold gap-y-1 pl-3">
                    <span>신규가입</span>
                    <span>35입</span>
                  </div>
                </div>
              </div>
              {/* ----------2nd----------------- */}
              <div className="flex justify-center items-center w-full">
                <div className="flex justify-start items-center w-full">
                  <div className="bg-blue-200 rounded-full p-2 flex justify-center items-center">
                    <img src={icre3x} alt="" className="w-11" />
                  </div>
                  <div className="flex-col flex justify-center items-start text-[#30313c] text-lg font-semibold gap-y-1 pl-3">
                    <span>리퍼럴</span>
                    <span>75입</span>
                  </div>
                </div>
                <div className="flex justify-end md:justify-start items-center w-full">
                  <div className="bg-blue-200 rounded-full p-2 flex justify-center items-center">
                    <img src={icuserplus3x} alt="" className="w-11" />
                  </div>
                  <div className="flex-col flex justify-center items-start text-[#30313c] text-lg font-semibold gap-y-1 pl-3">
                    <span>신규가입</span>
                    <span>21입</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-y-2 md:gap-y-0 justify-between items-center w-full  pt-10 px-3 md:px-0">
              <div className="flex justify-center items-center w-full">
                <div className="flex justify-start items-center w-full">
                  <div className="bg-blue-200 rounded-full p-2 flex justify-center items-center">
                    <img src={icm3x} alt="" className="w-11" />
                  </div>
                  <div className="flex-col flex justify-center items-start text-[#30313c] text-lg font-semibold gap-y-1 pl-3">
                    <span>매출</span>
                    <span>1,796,200입</span>
                  </div>
                </div>
                <div className="flex justify-end md:justify-start items-center w-full pr-4 md:pr-0">
                  <div className="bg-blue-200 rounded-full p-2 flex justify-center items-center">
                    <img src={gurib2581} alt="" className="w-11" />
                  </div>
                  <div className="flex-col flex justify-center items-start text-[#30313c] text-lg font-semibold gap-y-1 pl-3">
                    <span>ROI</span>
                    <span>731%</span>
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center w-full  ">
                <div className="flex justify-start items-center w-full">
                  <div className="bg-blue-200 rounded-full p-2 flex justify-center items-center">
                    <img src={icm3x} alt="" className="w-11" />
                  </div>
                  <div className="flex-col flex justify-center items-start text-[#30313c] text-lg font-semibold gap-y-1 pl-3">
                    <span>매출</span>
                    <span>3,199,800입</span>
                  </div>
                </div>
                <div className="flex justify-end md:justify-start items-center w-full pr-4 md:pr-0">
                  <div className="bg-blue-200 rounded-full p-2 flex justify-center items-center">
                    <img src={gurib2581} alt="" className="w-11" />
                  </div>
                  <div className="flex-col flex justify-center items-start text-[#30313c] text-lg font-semibold gap-y-1 pl-3">
                    <span>ROI</span>
                    <span>741%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-between items-center px-10 md:px-44 pt-20 w-full">
            <div className="flex flex-col md:flex-row justify-between items-center w-full">
              <div className="w-full">
                <img src={imgMac} alt="" width={450} />
              </div>
              <div className="w-full  flex justify-center items-center md:pl-20">
                <div className="flex justify-center items-end flex-col gap-y-3 relative">
                  <div className="absolute left-4 -top-3">
                    <img src={imgPhone} alt="" width={90} />
                  </div>
                  <div className="text-sky-600 font-bold text-sm pb-3">
                    리퍼럴 마케팅
                  </div>
                  <div className=" text-xl md:text-2xl text-[#30313c] font-extrabold">
                    기존 고객으로
                  </div>

                  <div className="text-xl md:text-2xl text-[#30313c] font-extrabold">
                    신규 고객을 유입 시키세요
                  </div>
                  <a
                    href={Pdf}
                    target="_blank"
                    className="px-4 py-2  text-sm md:text-lg text-white bg-blue-500 rounded-md shadow hover:bg-gray-100 hover:text-[#30313c]"
                  >
                    서비스 소개서 다운
                  </a>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center gap-y-8 md:gap-y-0 flex-wrap md:flex-nowrap py-10 md:px-10 w-full">
              <div className="flex flex-col justify-center items-center gap-y-4">
                <div className="flex justify-center items-center rounded-2xl bg-white drop-shadow-md pl-[17px] pr-[17px] pt-[17px] pb-[17px]">
                  <div className="hidden md:block">
                    <img src={imgCoinUp} alt="" width={40} />
                  </div>
                  <div className="block md:hidden">
                    <img src={imgCoinUp} alt="" width={20} />
                  </div>
                </div>

                <div className="text-sm md:text-md font-bold text-[#30313c]  flex flex-col justify-center items-center">
                  <div> 리퍼럴 프로그램을 </div>
                  <div> 가진 회사들은타사대비</div>
                  <div> 수익률 86% 증가</div>
                  <div className="text-xs pt-4">출처 : 하인즈 마케팅</div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-y-4 pr-2 md:pr-0">
                <div className="flex justify-center items-center rounded-2xl bg-white drop-shadow-md pl-[17px] pr-[17px] pt-[19px] pb-[19px] md:pt-[20px] md:pb-[20px]">
                  <div className="hidden md:block">
                    <img src={imgGroup} alt="" width={40} />
                  </div>
                  <div className="block md:hidden">
                    <img src={imgGroup} alt="" width={21} />
                  </div>
                </div>

                <div className="text-sm md:text-md font-bold text-[#30313c]  flex flex-col justify-center items-center">
                  <div>리퍼럴 마케팅으로 </div>
                  <div> 고객획득 비용</div>
                  <div> 35%감소</div>
                  <div className="text-xs pt-4">출처 : 내부 자료</div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-y-4">
                <div className="flex justify-center items-center rounded-2xl bg-white drop-shadow-md pl-[17px] pr-[17px] pt-[19px] pb-[19px] md:pt-[22px] md:pb-[22px]">
                  <div className="hidden md:block">
                    <img src={imgUserThumb} alt="" width={40} />
                  </div>
                  <div className="block md:hidden">
                    <img src={imgUserThumb} alt="" width={22} />
                  </div>
                </div>

                <div className="text-sm md:text-md font-bold text-[#30313c]  flex flex-col justify-center items-center">
                  <div>92%의 고객들이</div>
                  <div> 광고보다</div>
                  <div> 지인의 추천을 더 신뢰</div>
                  <div className="text-xs pt-4">출처 : 닐슨</div>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center gap-y-4">
                <div className="flex justify-center items-center rounded-2xl bg-white drop-shadow-md pl-[19px] pr-[19px] pt-[17px] pb-[17px]">
                  <div className="hidden md:block">
                    <img src={imgCartUp} alt="" width={37} />
                  </div>
                  <div className="block md:hidden">
                    <img src={imgCartUp} alt="" width={19} />
                  </div>
                </div>

                <div className=" text-sm md:text-md font-bold text-[#30313c]  flex flex-col justify-center items-center">
                  <div>지인에게 추천 받으면</div>
                  <div> 구매할 확률이</div>
                  <div> 4배 이상높음</div>
                  <div className="text-xs pt-4">출처 : 닐슨</div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center w-full pt-20 md:pt-40">
            <div className="flex flex-col justify-start items-start w-full px-9 md:px-72 gap-y-5 pb-20">
              <div className="text-base text-sky-600 font-bold">CRM</div>
              <div className="text-lg md:text-2xl font-bold text-[#30313c]">
                정확하고 입체적으로 분석된 고객 정보로
              </div>
              <div className="text-lg md:text-2xl font-bold text-[#30313c]">
                세일즈를 시작 할 수 있어요
              </div>
            </div>
            <div>
              <img src={imgCRM} alt="" width={900} />
            </div>
          </div>

          <div className="flex justify-start items-center relative pt-20 w-full">
            <div className="flex flex-col justify-center items-start text-white text-xs md:text-xl font-bold absolute w-full px-10 md:px-64 gap-y-3">
              <div>결제없이</div>
              <div> 무료로 사용해보세요ß</div>
              <div className="pt-4">
                <a
                  href="#"
                  className="px-6 py-2  text-[#30313c] text-[9px] md:text-sm bg-white rounded-md shadow hover:bg-[#30313c] hover:text-white"
                >
                  무료로 시작하기
                </a>
              </div>
            </div>

            <img src={imgLaptop} alt="" />
          </div>
        </section>
      </div>
      <div className="w-screen mx-auto py-4 flex justify-center bg-[#1a1a1a]">
        <Footer classname="text-white" />
      </div>
    </>
  );
}
