import { useState } from "react";

export default function Footer(props) {
  const { classname } = props;
  return (
    <footer className=" text-center lg:text-left">
      <div className="container p-6">
        <div className="grid lg:grid-cols-4 md:grid-cols-2">
          <div className="mb-6">
            <ul className="list-none mb-0">
              <li>
                <a href="#!" className={`${classname}`}>
                  상호명
                </a>
              </li>
              <li>
                <a href="#!" className={`${classname}`}>
                  대표자
                </a>
              </li>
              <li>
                <a href="#!" className={`${classname}`}>
                  사업자등록번호
                </a>
              </li>
              <li>
                <a href="#!" className={`${classname}`}>
                  대표번호
                </a>
              </li>
              <li>
                <a href="#!" className={`${classname}`}>
                  주소
                </a>
              </li>
            </ul>
          </div>
          <div className="mb-6">
            <ul className="list-none mb-0">
              <li>
                <a href="#!" className={`${classname}`}>
                  오픈포맷 주식회사
                </a>
              </li>
              <li>
                <a href="#!" className={`${classname}`}>
                  KIM THOMAS SUNGJIN
                </a>
              </li>
              <li>
                <a href="#!" className={`${classname}`}>
                  823 86 02119
                </a>
              </li>
              <li>
                <a href="#!" className={`${classname}`}>
                  010 9705 5145
                </a>
              </li>
              <li>
                <a href="#!" className={`${classname}`}>
                  서울 구로구 디지털로33길 11
                </a>
              </li>
            </ul>
          </div>
          <div className="mb-6">
            <ul className="list-none mb-0"></ul>
          </div>
          <div className="mb-6">
            <ul className="list-none mb-0">
              <li>
                <a href="https://www.incento.kr/terms" className={`${classname}`}>
                  서비스 이용약관
                </a>
              </li>
              <li>
                <a href="https://www.incento.kr/privacy" className={`${classname}`}>
                  개인정보 처리방침
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
