import { useState } from "react";
import { Link } from "react-router-dom";
import imgLogo from "../img/로고.png";
import imgLogo2 from "../img/로고2.png";
export default function NavBar(props) {
  const { navbarColour, navbarbgColour, blackText, whiteText } = props;
  const [navbar, setNavbar] = useState(false);

  return (
    <>
      <nav
        className={`hidden md:flex w-full  fixed px-10 z-50  justify-between items-center 
      
       ${navbarColour ? "bg-white" : `${navbarbgColour}`} 
      
      `}
      >
        <div className=" justify-start px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8 w-full gap-x-10 ">
          <div>
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              <Link to="/">
                {navbarColour ? (
                  <img src={imgLogo} alt="Logo" />
                ) : (
                  <img src={imgLogo2} alt="Logo" />
                )}
              </Link>
              <div className="md:hidden">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-[#30313c]"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-[#30313c]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                navbar ? "block" : "hidden"
              }`}
            >
              <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                <li
                  className={` hover:text-gray-300 ${
                    navbarColour ? `${blackText}` : `${whiteText}`
                  }`}
                >
                  <a href="/#home">서비스 소개</a>
                </li>
                <li
                  className={` hover:text-gray-300 ${
                    navbarColour ? `${blackText}` : `${whiteText}`
                  }`}
                >
                  <Link to="/serviceIntro">기능 소개</Link>
                </li>
                <li
                  className={` hover:text-gray-300 ${
                    navbarColour ? `${blackText}` : `${whiteText}`
                  }`}
                >
                  <Link to="/price">가격안내</Link>
                </li>
              </ul>

              <div className="mt-3 space-y-2 md:hidden  ">
                <a
                  href="https://www.incento.kr/register"
                  className="inline-block w-full px-4 py-2 text-center text-[#30313c] rounded-md  hover:bg-gray-800 hover:text-white"
                >
                  회원가입
                </a>
                <a
                  href="https://www.incento.kr/login"
                  className="inline-block w-full px-4 py-2 text-center text-white bg-[#30313c] rounded-md shadow hover:bg-white hover:text-[#30313c]"
                >
                  로그인
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className=" hidden space-x-2 w-full max-w-xs md:flex justify-end items-center px-6">
          <a
            href="https://www.incento.kr/register"
            className={`px-4 py-1.5 ${
              navbarColour
                ? `${blackText} hover:bg-[#30313c] hover:text-white`
                : `${whiteText} hover:text-[#30313c] hover:bg-white`
            } rounded-md   `}
          >
            회원가입
          </a>
          <a
            href="https://www.incento.kr/login"
            className={`px-4 py-1 ${
              navbarColour
                ? `${whiteText} bg-[#30313c] ring ring-[#30313c]  hover:ring-[#47485a]  hover:shadow-md hover:shadow-[#47485a]  active:ring-[#30313c]  active:shadow-none`
                : `${blackText} bg-white ring ring-white hover:ring-gray-300 hover:shadow-md hover:shadow-gray-300 active:ring-white active:shadow-none`
            }   rounded-md shadow `}
          >
            로그인
          </a>
        </div>
      </nav>
      <nav
        className={`w-full md:hidden fixed px-10 z-50 flex justify-between items-center  bg-white`}
      >
        <div className=" justify-start px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8 w-full gap-x-10 ">
          <div>
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              <Link to="/">
                <img src={imgLogo} alt="Logo" />
              </Link>
              <div className="md:hidden">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-[#30313c]"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-[#30313c]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                navbar ? "block" : "hidden"
              }`}
            >
              <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                <li className={` hover:text-gray-300 text-[#30313c]`}>
                  <a href="/#home">서비스 소개</a>
                </li>
                <li className={` hover:text-gray-300 text-[#30313c]`}>
                  <Link to="/serviceIntro">기능 소개</Link>
                </li>
                <li className={` hover:text-gray-300 text-[#30313c]`}>
                  <Link to="/price">가격안내</Link>
                </li>
              </ul>

              <div className="mt-3 space-y-2 md:hidden  ">
                <a
                  href="https://www.incento.kr/register"
                  className="inline-block w-full px-4 py-2 text-center text-[#30313c] rounded-md  hover:bg-gray-800 hover:text-white"
                >
                  회원가입
                </a>
                <a
                  href="https://www.incento.kr/login"
                  className="inline-block w-full px-4 py-2 text-center text-white bg-[#30313c] rounded-md shadow hover:bg-white hover:text-[#30313c]"
                >
                  로그인
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className=" hidden space-x-2 w-full max-w-xs md:flex justify-end items-center px-6">
          <a
            href="https://www.incento.kr/register"
            className={`px-4 py-1.5 ${
              navbarColour
                ? `${blackText} hover:bg-[#30313c] hover:text-white`
                : `${whiteText} hover:text-[#30313c] hover:bg-white`
            } rounded-md   `}
          >
            회원가입
          </a>
          <a
            href="https://www.incento.kr/login"
            className={`px-4 py-1 ${
              navbarColour
                ? `${whiteText} bg-[#30313c] ring ring-[#30313c]  hover:ring-[#47485a]  hover:shadow-md hover:shadow-[#47485a]  active:ring-[#30313c]  active:shadow-none`
                : `${blackText} bg-white ring ring-white hover:ring-gray-300 hover:shadow-md hover:shadow-gray-300 active:ring-white active:shadow-none`
            }   rounded-md shadow `}
          >
            로그인
          </a>
        </div>
      </nav>
    </>
  );
}
