import {useEffect, useState } from "react";
import Footer from "../Components/Footer";
import 리퍼럴1 from "../img/PageService/리퍼럴1.png";
import 리퍼럴2 from "../img/PageService/리퍼럴2.png";
import 리퍼럴3 from "../img/PageService/리퍼럴3.png";
import 위젯바4 from "../img/PageService/위젯바4.png";
import 리퍼럴위젯 from "../img/PageService/리퍼럴 위젯.png";
import 마스크그룹17 from "../img/PageService/마스크 그룹 17.png";
import 그룹2544 from "../img/PageService/그룹 2544.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, EffectFade, Navigation } from "swiper";
import NavBar from "../Components/NavBar";

export default function PageServiceIntro() {
  const [navbarColour, setnavbarColour] = useState(true);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0
    });
  }, []);
  return (
    <>
      <div className="w-screen mx-auto pb-20">
        <NavBar
          navbarbgColour="bg-white"
          navbarColour={navbarColour}
          blackText="text-[#30313c]"
          whiteText="text-white"
        />
      </div>
      <div className="bg-[#f3fafd] ">
        <div className="pt-20 md:px-56">
          <div className="flex flex-col justify-center items-center md:items-start gap-y-5 md:px-10">
            <div className="text-sky-600 font-bold pb-2">리퍼럴 마케팅</div>
            <div className="font-bold  text-xl md:text-3xl text-gray-800">
              5분만에 가장 효과적인 리퍼럴 마케팅으로
            </div>
            <div className="font-bold text-xl md:text-3xl text-gray-800">
              신규고객을 획득하세요
            </div>
          </div>

          <div className="w-full  rounded-lg drop-shadow-md mt-16">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              effect={"fade"}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              //   pagination={{
              //     clickable: true,
              //   }}
              //   navigation={true}
              modules={[Autoplay, EffectFade, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src={리퍼럴1} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={리퍼럴2} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={리퍼럴3} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={위젯바4} alt="" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="pt-20 md:pt-32 md:px-56 relative ">
          <div className="flex flex-col top-40 justify-center items-center md:items-start  gap-y-5 md:px-10">
            <div className="text-sky-600 font-bold pb-2">리퍼럴 위젯</div>
            <div className="font-bold text-xl md:text-3xl text-gray-800">
              내 사이트에 어울리게 커스텀하고
            </div>
            <div className="font-bold text-xl md:text-3xl text-gray-800">
              리퍼럴 마케팅을시작해보세요
            </div>
          </div>

          <div className=" w-full pb-96  rounded-lg drop-shadow-md mt-10 md:mt-16 flex justify-end items-center ">
            <img
              src={리퍼럴위젯}
              alt=""
              width={650}
              className="absolute bottom-16"
            />
          </div>
        </div>
        <div className="relative">
          <div className="flex flex-col justify-center text-gray-800 items-center md:items-start gap-y-3 md:px-10 md:absolute md:text-white md:top-24 md:left-36 pb-3 md:pb-0">
            <div className=" font-bold text-sm pb-2 ">
              정확한 데이터로 정확한 CRM
            </div>
            <div className="font-bold text-xl md:text-2xl  ">
              정확하고 입체적으로 분석된 고객정보로
            </div>
            <div className="font-bold text-xl md:text-2xl  ">
              세일즈를 시작 할 수 있어요
            </div>
          </div>
          <img src={마스크그룹17} alt="" />
        </div>

        <div className="py-20 md:px-56 ">
          <div className="flex flex-col md:flex-row justify-start items-center ">
            <img src={그룹2544} alt="" width={350} />
            <div className="flex flex-col justify-center items-center pt-5 md:items-start md:pl-32">
              <div className="text-gray-800 font-bold text-xl md:text-2xl">
                결제 없이{" "}
              </div>
              <div className="text-gray-800 font-bold text-xl md:text-2xl">
                무료로 사용해보세요!
              </div>
              <div className="bg-gray-800 text-white px-8 cursor-pointer py-2 rounded-[4px] flex justify-center items-center text-md md:text-lg mt-6">
                무료로 시작하기
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-screen mx-auto py-4 flex justify-center bg-white">
        <Footer />
      </div>
    </>
  );
}
